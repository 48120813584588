import { convertNullIfEmpty } from "@/utils/null-check";
import { getFullAddress } from "@/utils/string";

class ProjectUnitTypeModel {
    static postPayload(e) {
        return {
            projectId: e.projectId.toString() || "",
            name: e.name || "",
            propertyTypeId: e.propertyTypeId.toString() || "",
            completionDate: e.completionDate || "",
            siteMap: e.siteMap || [],
            projectPhases: e.projectPhases.map((e) => {
                return {
                    id: e.id
                };
            }),
            vrLink: e.vrLink ? getFullAddress(e.vrLink) : "",
            bookingFee: convertNullIfEmpty(e.bookingFee) || "0.00",
            isEditBookingFee: e.isEditBookingFee
        };
    }

    static getToEditResponse(e) {
        return {
            projectId: e.project.id || "",
            name: e.name || "",
            propertyTypeId: e.propertyType ? e.propertyType.id : "",
            completionDate: e.completionDate || "",
            siteMap: e.siteMap || [],
            projectPhases: e.projectPhases || [],
            vrLink: e.vrLink ? getFullAddress(e.vrLink) : "",
            bookingFee: e.bookingFee || "",
            isEditBookingFee: e.isEditBookingFee
        };
    }

    static postInteractiveSitePlan(e) {
        return {
            image: e.image || [],
            geoJson: e.geoJson || []
        };
    }
}

export default ProjectUnitTypeModel;
